export const environment = {
  production: false,
  webSocketApi: 'https://qa.v2.api.mercadomeet.com/socket.io/socket.io.js',
  api: 'https://qa.v2.api.mercadomeet.com/api/v2',
  mercadoWeb: 'https://qa.mercadomeet.com.co',
  mercadoWebArg: 'https://qa.mercadomeet.com.ar',
  webGame: 'https://qa.meetcoin.cash',
  appName: 'MERCADOMEET',
  maxNumberCanSelected: 9,
  initLives: 0,
  initTries: 0,
  maxTries: 10,
  maxVideoSizeMB: 50,
  wompiKeys: {
    baseURL: 'https://sandbox.wompi.co/v1',
    publicKey: 'pub_test_wq0LA9hw1bzqLYDHUwAt6afPxraxzcaD',
  },
  wompiBundle: 'https://checkout.wompi.co/widget.js',
  modoBundle: 'https://ecommerce-modal.preprod.modo.com.ar/bundle.js',
  chatEncryptKey: 'eb516a3cc438c5c52d93c34875161e0d86ff33f664cfc0c32f88bd4ef2443572'
};
